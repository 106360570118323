import styled from 'styled-components';

const HeaderHolder = styled.div`
    width: 100%;
    z-index: 1;

    .emergency-notification {
        background-color: #CACACA;
        text-align: center;
        padding: 8px;
    }

    .header {
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 0 4rem;
        height:90px;
        background-color: #003359;
        background-size: contain;
        @media (max-width: 720px) {
            flex-flow: wrap;
            justify-content: space-between;
        }
        &__logo {
            width: 15rem;
            height: 9rem;
            object-fit: contain;
            filter: brightness(0) invert(1);
        }
        &__profile {
            &__icon {
                width: 3rem;
                height: 3rem;
                color: white;
            }
        }
    }

    .nav-profile {
        position: absolute;
        right: 5%;
    }

    .input { 
        position: absolute;
        right: 17%;
        font-size: 0.8em;

        @media only screen and (max-width: 991px) {
            position: absolute;
            top: 86px;
            right: 3%;
            z-index: 3;

        }
    }

    .drop-container{
        position: absolute;
        color: white;
        display: inline-flex;
        width: 140px;
        line-height: 25px;
        right: 2%;
    }

    .ui.dropdown {
        display: flex;
        align-items: center;
        margin-left: 5px;
        p {
            color: white;
        }
        .icon {
            color: white;
            margin-left: 5px;
        }
        .menu {
            left: auto;
            right: 0;
            &.visible {
                display: block;
            }
            .item {
                font-size: 1.6rem;
            }
        }
    }
`;

export default HeaderHolder;