import styled from 'styled-components';

const PaginationHolder = styled.div`
    display: flex;
    background-color: rgba(50,63,63,0.08);
    .pagination-container {
        margin: 0 auto;
        background-color: none;
        display:block;
        
        .pagination {
            height: 40px;
            border: none;
            border-top: none;
            box-shadow: none;
            border-left: 1px lightgray solid;
            border-right: 1px lightgray solid;
            border-top: 1px lightgray solid;
        }
    }
    .visible {
        display:block;
    }
    .hidden {
        display: none;
    }
`;

export default PaginationHolder;