import styled from 'styled-components';

const MetadataTileHolder = styled.div`
    .file-box {
        display: flex;
        margin: 20px 0;
        .icon-box {
            width: 40%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .form-box {
            width: 60%;
            padding: 0 25px;

            .input-container {
                margin: 5px 0;
            }

            .dropdown {
                width: 100%;
                margin: 0;
                padding: 6px;
                border: 1px solid rgba(34,36,38,.15);
                border-radius: .28571429rem;
            }
        }
        
        .icon-container {
            max-width: 60px;
            margin: 0 auto;
        }
    }
`;

export default MetadataTileHolder;