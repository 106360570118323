import React, { useState, useEffect } from 'react';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMatrixHolder from "./CardMatrixStyles";
import style from './CardMatrix.module.css';
import { getItem } from "@craftercms/content";

const CardMatrix = (props) => {
    
    const {itemsToShow = 4, url} = props; 
    const [cards, setCards] = useState([]);
    const [showMore, setShowMore] = useState(itemsToShow);
    const [showMoreLabel, setShowMoreLabel] = useState("Show More");
    const [enableMoreButton, setEnableMoreButton] = useState( false);

    const showMoreClick = () => {
        if(showMore < cards.length) {
            setShowMore(showMore + itemsToShow);
        } else{
            setEnableMoreButton(true);
        }
    }

    useEffect(() => {
        getItem(url).subscribe(data => {
            const { component } = data.descriptorDom;
            setShowMoreLabel(component.showMoreButtonLabel_s && component.showMoreButtonLabel_s !== "" ? component.showMoreButtonLabel_s : "Show More");
            setCards(component.cards_o.item);
        })
    }, [url]);

    const handleClick = (card) => {
        const target = card.newWindow_b === "true" ? "_blank" : "_self";
        window.open(card.link_s, target);
    }

    return (
        <CardMatrixHolder>
            <div className={style.wrapper}>
                <div className={style.cardWrapper}>
                    {cards.slice(0, showMore).map((card, index) =>
                        <div key={index} className={`${style.card} ${index % 4 !== 0 ? style.space : ''}`}>
                            <Card style={{height: '100%'}} onClick={() => handleClick(card)}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt="image test"
                                        height="200"
                                        image={card.image_s}
                                        title="image test"
                                        className={style.img}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom className={style.tile} variant="h2" component="h2">
                                            { card.title_s }
                                        </Typography>
                                        <Typography className={style.description} variant="h5" component="p">
                                            { card.description_t }
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    )}
                </div>
                <div className={style.buttonWrapper}>
                    <button className={`main-btn ${style.button} ${enableMoreButton ? style.buttonDisabled  : ''}`} onClick={showMoreClick}>{ showMoreLabel }</button>
                </div>
            </div>
        </CardMatrixHolder>
    )
}

export default CardMatrix;