import styled from 'styled-components';

const NavigationHolder = styled.div`
    background: #FFF;
    margin: 0 auto;
    padding: 0 5% 0 5%;
    margin-top: -2px;
    border-bottom: 1px solid #003359;
    //max-height: 49px;
    min-height: 49px;
    width: 100%;

    .main-nav{
        position: relative;
        min-height: 49px;

        .tablet-mobile-only {
            display: none;
        }
        @media only screen and (max-width: 991px) {
            &.computer-only {
                display: none;
            }

            .tablet-mobile-only {
                display: block:
            }
        }

        ul {
            position: absolute;
            margin: 0;
            list-style:none;
            padding: 0;

            li {
                display: inline-block;
                position: relative;
                line-height: 1.1;
                padding:11px 20px;
                font-size: 15px;
                font-family: Arial,serif;
                margin: 0;

                div {
                    position: relative;
                    color: black;
                }
            }
    
            li:hover {
                background-color: rgb(220, 235, 252);
               
                > ul {
                    display: block;
                }

                div {
                    cursor: pointer;
                }
            } 
        }

        ul ul {
            position: absolute;
            display: none;
            margin: 0;
            z-index: 10;
            min-width: 205px;
            border-radius: 0 0 .28571429rem .28571429rem;
            background: #fff;
            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
            top: 50px;
            left: -50%;

            :after {
                display: block;
                position: absolute;
                pointer-events: none;
                content: "";
                visibility: visible;
                transform: rotate(45deg);
                width: .5em;
                height: .5em;
                box-shadow: -1px -1px 0 0 rgb(34 36 38 / 15%);
                background: #fff;
                z-index: -1;
                top: -4px;
                left: 50%;
                margin: 0 0 0 -.25em;
            }

            li {
                display: block;
                padding-right: 7px;
                div {
                    display: flex;
                }
            } 
        }

        ul ul ul {
            position: absolute;
            top: 0;
            left: 100%;

            &:after {
                display: none;
            }
        }

        > ul > li {
            padding-top: 0;
            padding-bottom:0;
            
            &:before {
                position: absolute;
                content: '';
                top: 0;
                right: 0;
                height: 100%;
                width: 1px;
                background: rgba(34,36,38,.1);
            }

            &:hover {
                &> div .underline {
                    position relative;

                    &:before {
                        display: inline-block;
                        content: "";
                        width: 100%;
                        border-bottom: 2px solid #ac4fc6;
                        position: absolute;
                        top: 18px;
                    }
                }
            }

            > div {
                display: flex;
                align-items: center;
                height: 100%;
                min-height: 49px  
            }

            .active .underline {
                position relative;

                &:before {
                 display: inline-block;
                 content: "";
                 width: 100%;
                 border-bottom: 2px solid #ac4fc6;
                 position: absolute;
                 top: 18px;
                }
            } 
        }

       
    }
`;

export default NavigationHolder;