import React, { useEffect, useState } from "react";
import { getItem } from '@craftercms/content';

import ArticleHolder from './ArticlePageStyles';
import MainHolder from '../../MainStyles';
import DocumentTree from "../../components/DocumentTree/DocumentTree";
import LatestDocuments from "../../components/LatestDocuments/LatestDocuments";
import Metadata from "../../components/Metadata/Metadata";
import LeftNavigation from '../../components/LeftNavigation/LeftNavigation';
import CardMatrix from '../../components/CardMatrix/CardMatrix';
import Carousel from "../../components/Carousel/Carousel";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import {crafterConf, httpGet} from "@craftercms/classes";
import {composeUrl} from "@craftercms/utils";

const ArticlePage = (props) => {
    const { url } = props
    const [sections, setSections] = useState([]);
    const [areas, setAreas] = useState([]);
    const [carouselUrl, setCarouselUrl] = useState(null);
    const [cardMatrixUrl, setCardMatrixUrl] = useState(null);
    const [userRole, setUserRole] = useState("");

    useEffect(() => {
        const config = crafterConf.getConfig();
        httpGet(
            composeUrl(config, process.env.REACT_APP_ROLES_ENDPOINT),
            {crafterSite: config.site}
        ).subscribe(data => {
            if (data && data.length > 0) {
                setUserRole(data[0].authority);
            }
        });
    });

    useEffect(() => {
        getItem('/site/website' + url + '/index.xml').subscribe((item) => {
            const { page } = item.descriptorDom;
            setSections(page.mainSection_o && page.mainSection_o.item ? page.mainSection_o.item : sections);
            setAreas(page.areas_o && page.areas_o.item ? page.areas_o.item : areas);
            setCarouselUrl(page.carousel_o.item ? page.carousel_o.item[0].include : carouselUrl);
            setCardMatrixUrl(page.cardMatrix_o.item ? page.cardMatrix_o.item[0].include : carouselUrl);
        });
    }, [url]);

    const refreshAreas = () => {
        getItem('/site/website' + url + '/index.xml').subscribe((item) => {
            const { page } = item.descriptorDom;
            setAreas(page.areas_o ? page.areas_o.item : areas);
        });
    }

    const total = areas.reduce((total, item) => item.documents_o.item ? total + item.documents_o.item.length : total, 0);

    const showLeftSection = (area) => {
        return area.documentTitle_t || area.documents_o.item
    }

    const showRightSection = (area) => {
        return area.rightSectionTitle_t || area.rightSection_html
    }

    const getSmallSection = (area) => {
        const showLeft = showLeftSection(area);
        const showRight = showRightSection(area);
        return (showLeft ? (showRight ? "full" : "left") : "right");
    }

    return (
        <>
            <MainHolder>
                <div className="main-container">
                    <div className="left-content">
                        <LeftNavigation path={url} type="ARTICLE"/>
                    </div>
                    <ArticleHolder>
                        <div className="main-content">
                            <div className="article__content">
                                {carouselUrl && <Carousel url={carouselUrl}/>}
                                <div className="article__content__text">
                                    {sections.map((item, index) =>
                                        <div key={index}>
                                            {
                                                item.title_t && item.title_t !== "" &&
                                                <div className="article__header">
                                                    <div>
                                                        <h1>{item.title_t}</h1>
                                                    </div>
                                                </div>
                                            }
                                            <div key={ index } dangerouslySetInnerHTML={{__html: item.section_html}}/>
                                        </div>
                                    )}
                                </div>
                                {cardMatrixUrl && <CardMatrix itemsToShow={4} url={cardMatrixUrl} />}
                                <div className="article__content__small">
                                    {areas.map((item, index) =>
                                        <div key={ index } className={ getSmallSection(item) }>
                                            {
                                                (getSmallSection(item) === 'left' || getSmallSection(item) === 'full') &&
                                                <div className={`section-${getSmallSection(item)}`}>
                                                    <h2 className={item.documentTitle_t && item.documentTitle_t !== "" ? 'article__header' : 'no-vis'}>{ item.documentTitle_t }</h2>
                                                    {item.documents_o.item && <DocumentTree userRole={userRole} url={url} name={index} refresh={refreshAreas} data={ item.documents_o.item } titleExist={item.documentTitle_t && item.documentTitle_t !== "" ? "titleExist" : ""}/>}
                                                </div>
                                            }
                                            {
                                                (getSmallSection(item) === 'right' || getSmallSection(item) === 'full') &&
                                                <div className={`section-${getSmallSection(item)}`}>
                                                    <h2 className={item.rightSectionTitle_t ? 'article__header' : 'no-vis'}>{ item.rightSectionTitle_t }</h2>
                                                    <div dangerouslySetInnerHTML={{__html: item.rightSection_html}}/>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                                <LatestDocuments url={`/site/website${url}/index.xml`} size={total}/>
                            </div>
                            
                        </div>
                    </ArticleHolder>
                </div>
            </MainHolder>
            <Metadata url={url} refresh={refreshAreas} />
            <ConfirmationDialog />
        </>
    )
}

export default ArticlePage;