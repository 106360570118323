import React from 'react';

import {Search, Grid, Icon} from 'semantic-ui-react';
import Pagination from '../Pagination/Pagination';
import { connect } from 'react-redux'
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Link } from 'react-router-dom';
import SearchCHolder from './SearchCStyles';
import { handleKeyword } from "../../actions/search";
import { bindActionCreators } from "redux";
import { close, open, handleDisable } from "../../actions/confirmationDialog";
import {crafterConf, httpPost} from "@craftercms/classes";
import {composeUrl} from "@craftercms/utils";

const SearchC = (props) => {

    const renderSummaries = (summaries) => (
        summaries && summaries.map((summaryHL, index) =>
            <p key={ index } className="desc" dangerouslySetInnerHTML={{__html: summaryHL}} />
        )
    )

    const renderTags = (tags) => {
        if (tags) {
            if (Array.isArray(tags.item)) {
                return tags.item.map((tag, index) =>
                    <p key={index} className="category">{tag.value_smv}</p>
                )
            }
            return <p className="category">{tags.item.value_smv}</p>
        }
    }

    const renderDocTags = (localId) => {
        let path = localId.substring(25, localId.length);
        if (path.includes('/')) {
            return <p className="category">{ path.split('/')[0] }</p>
        }
        return <></>
    }

    const renderArticle = (item, index) => (
		<div className="result" key={ index }>
            <Link className="title" to={ item.localId.replace('/site/website', '').replace('/index.xml', '') }>{ item.metadataTitle_t }</Link>
            {renderSummaries(item.highlightFields.allHL)}
            <p className="date">{ new Intl.DateTimeFormat().format(Date.parse(item.crafterPublishedDate_dt)) }</p>
            {renderTags(item.tags_o)}
		</div>
	)

    const deleteDocument = (path) => {
        const config = crafterConf.getConfig();
        if (path && path !== "") {
            props.handleDisable(true);
            const url = composeUrl(config, process.env.REACT_APP_DOCUMENT_REMOVE + '?crafterSite=' + config.site);
            httpPost(url, {path: path}).subscribe(() => {
                props.close();
                props.refresh();
            });
        }
    }

	const renderDocument = (item, index) => (
        <div className="result" key={ index }>
            {
                props.userRole === 'admin' &&
                <span className="removeBtn" onClick={ () => props.open(item.title_t ? item.title_t : item.localId.split('/').pop(), item.localId, deleteDocument) }>
                    <Icon name='remove' bordered={true} />
                </span>
            }

            <a href={item.localId} target="_blank" className="title">{item.title_t ? item.title_t : item.localId.split('/').pop()}</a>

            {renderSummaries(item.highlightFields.allHL)}
            <div>
                <div className="file-icon"><FileIcon
                    extension={item.localId.split('.').pop()} {...defaultStyles[item.localId.split('.').pop()]}/></div>
                <p className="row"> Author: {item.author_t}</p>
            </div>
            {(item.created || item.createdDate) &&
                <p className="date">{new Intl.DateTimeFormat().format(Date.parse(item.created ? item.created : item.createdDate))}</p>
            }
            {renderDocTags(item.localId)}
        </div>
    )

    const renderResults = (items) => {
		return items.map((item, index) => {
			if (item.localId.startsWith('/site/website')) {
				return renderArticle(item, index);
			} else {
				return renderDocument(item, index);
			}
		})
	}

    return (
        <SearchCHolder>
            <div className="search-container">
                <div dangerouslySetInnerHTML={{__html: props.header}}/>
                <Grid>
                    <Grid.Column width={16}>
                        <Search
                            loading={ props.isLoading }
                            onSearchChange={ (e, { value })  => props.handleKeyword(value) }
                            value={ props.keyword }
                            placeholder={ props.placeHolder }
                        />
                    </Grid.Column>
                </Grid>
            </div>
            <div className={`results-container ${props.total > 0 ? "visible" : "hidden"}`}>
                <p className={`results-container__header ${(props.total > 0) ? "visible" : "hidden"}`}>
                    <span>{ props.total } results for <strong>{ props.keyword }</strong></span>
                </p>
                {renderResults(props.items)}
            </div>
            <Pagination sValue={ 10 } sResults={ 1 }/>
			{props.total === 0 &&
				<div className="results-container">
					<p className="results-container__header" >{ props.noResultTitle }<strong> { props.keyword }</strong></p>
					<div dangerouslySetInnerHTML={{__html: props.noResultBody}}/>
				</div>
			}
        </SearchCHolder>
    )
}

const mapStateToProps = ({ search }) => ({
    keyword: search.keyword,
    isLoading : search.isLoading,
    placeHolder : search.placeHolder,
    information_html: search.information_html,
	total: search.total
})

const mapDispatchToProps = dispatch => bindActionCreators({ handleKeyword, open, close, handleDisable }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchC);