import React, { useEffect, useState } from 'react';

import Carousel from 'react-material-ui-carousel'
import style from './Carousel.module.css';
import {getItem} from "@craftercms/content";


const NCarousel = ({ url }) => {

    const [interval, setInterval] = useState(5000);
    const [isAutoPlay, setAutoPlay] = useState(true);
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        getItem(url).subscribe(data => {
            const { component } = data.descriptorDom;
            setInterval(parseInt(component.speed_s));
            setAutoPlay(component.autoplay_b === "true");
            setSlides(component.slides_o.item);
        })
    }, [url])

    return (
        <Carousel
            navButtonsAlwaysVisible={slides.length > 1}
            indicators={slides.length > 1}
            navButtonsAlwaysInvisible={slides.length === 1}
            animation={"slide"}
            timeout={500}
            autoPlay={isAutoPlay}
            interval={interval}>
            {slides.map((slide, index) =>
                <Slide key={ index } slide={ slide } />
            )}
        </Carousel>
    )
}

const Slide = ({ slide }) => {

    const handleButton = (e) => {
        e.preventDefault();
        let target = slide.newWindow_b === "true" ? "_bank" : "_self";
        window.open(slide.link_s, target);
    }

    return (
        <div className={ style.wrapper }>
            <div className={ style.cardWrapper }>
                <h1 className={ style.cardTitle }>{ slide.title_s }</h1>
                <p className={ style.cardDescription }>{ slide.description_t }</p>
                <a className={ style.button } href="" onClick={handleButton}>{ slide.buttonLabel_s }</a>
            </div>
            <div className={ style.imageWrapper }>
                <img className={ style.image } src={ slide.image_s } />
            </div>
        </div>
    )
}

export default NCarousel;