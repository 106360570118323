import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';
import LatestDocumentsHolder from './LatestDocumentsStyles';

import { FileIcon, defaultStyles } from 'react-file-icon';
import { crafterConf, httpGet } from "@craftercms/classes";
import { composeUrl } from '@craftercms/utils';

const LatestDocuments = (props) => {

	const [documents, setDocuments] = useState([]);

	useEffect(() => {
		const config = crafterConf.getConfig();
		httpGet(
			composeUrl(config, process.env.REACT_APP_ARTICLE_LATEST_ENDPOINT),
			{articleUrl: props.url, crafterSite: config.site}
		).subscribe(data => {
			if (data) {
				setDocuments(data);
			}
		});
	}, [props.url, props.size]);
	
	const docOnClickHandler = (e, url) => {
		e.stopPropagation();

		let a = document.createElement('a');
		a.target="_blank";
		a.href=url;
		a.click();
	}

	const renderDocuments = (docs) => {
		return docs.map((document, index) =>
			<List.Item key={index} onClick={(e) => docOnClickHandler(e,document.url)}>
				<div className="doc-detail-ico">
					<FileIcon extension={ document.ext } {...defaultStyles[document.ext]}/>
				</div>
				{ document.name }
				<List.Content floated='right'>
					{document.date &&
						<span className="date">{ new Intl.DateTimeFormat().format(Date.parse(document.date)) }</span>
					}
					{!document.date &&
					<span className="date">NO DATA</span>
					}
				</List.Content>
			</List.Item>
		)
	}

	return (
		<LatestDocumentsHolder>
			{documents.length > 0 &&
				<>
					<h2 className="article__header">{ props.latestFileLabel }</h2>
					{documents.map((document, index ) =>
						<List divided key={index} verticalAlign='middle'>
							<p className="title">{ document.title }</p>
							{renderDocuments(document.values)}
						</List>
					)}
				</>
			}
		</LatestDocumentsHolder>
	);
}

const mapStateToProps = ({ descriptor }) => (
	{"latestFileLabel": descriptor.latestFileLabel}
);

export default connect(mapStateToProps)(LatestDocuments);