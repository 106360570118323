import React, { useState, useEffect, useRef } from 'react';
import DocumentTreeHolder from './DocumentTreeStyles';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { handleDisplay, handleFiles, handleEditUrl, handleFolder, handleIndex } from "../../actions/metadata";
import { close, open, handleDisable } from "../../actions/confirmationDialog";

import EditMetadata from "../EditMetadata/EditMetadata";

import {composeUrl} from "@craftercms/utils";
import {crafterConf, httpPost} from "@craftercms/classes";
import Item from "./Item";

const DocumentTree = (props) => {

    const [tree, setTree] = useState([]);
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        let newTree = [];
        for (let i in props.data) {
            let item = props.data[i];
            let path = item.key.replace('/static-assets/', '');
            if (path.toLowerCase().startsWith('documents/contracts/')) {
                if (props.userRole === process.env.REACT_APP_ROLE_PDF) {
                    if (!path.toLowerCase().endsWith('.pdf')) continue;
                } else if (props.userRole === process.env.REACT_APP_ROLE_PDF_WORD) {
                    if (!path.toLowerCase().endsWith('.pdf') && !path.toLowerCase().endsWith('.doc') && !path.toLowerCase().endsWith('.docx')) continue;
                } else if (props.userRole === process.env.REACT_APP_ROLE_GENERAL) {
                    if (path.toLowerCase().endsWith('.pdf') || path.toLowerCase().endsWith('.doc') || path.toLowerCase().endsWith('.docx')) continue;
                }
            }

            const getFileFolder = (wholePath, path, allItems, folder) => {
                let items = path.split('/');
                let name = items.shift();
                if (items.length > 0) {
                    let subItem = allItems.find(item => item.name === name);
                    if (subItem) {
                        getFileFolder(wholePath, path.replace(name + '/', ''), subItem.subItems, folder + '/' + name);
                    } else {
                        allItems.push({
                            name: name,
                            url: folder + '/' + name,
                            isFolder: true,
                            subItems: getFileFolder(wholePath, path.replace(name + '/', ''), [], folder + '/' + name)
                        })
                    }
                    return allItems;
                }
                allItems.push({
                    name: name,
                    url: wholePath,
                    isFolder: false
                });
                return allItems;
            }

            newTree = getFileFolder(item.key, path, newTree, '/static-assets');
        }
        setTree(newTree);
    }, [props.data, props.userRole]);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const { files } = event.target;
        if (files.length > 0) {
            let selectedFiles = [];
            Array.from(files).forEach(item => {
                selectedFiles.push({file: item, title:"", author:"", product:"", keywords: "", hasError: true});
            });
            props.handleFiles(selectedFiles);
            props.handleDisplay(true);
        }
    };

    const searchDocOnClickHanlder = (e,doctType) => {
        e.preventDefault();
        let tags = [];
        let url ='/search?keyword=*&tags='
        for (let i in tree) {
            const item = tree[i];
            for (let j in item.subItems) {
                let subItem = item.subItems[j];
                if (subItem.isFolder) {
                    tags.push(subItem.name);
                }
            }
        }
        window.open(url + tags, "_blank");
    }

    const onAddDocument = (value) => {
        props.handleIndex(props.name);
        props.handleFolder(value.url);
        handleClick();
    }
    
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const handleEdit = (item) => {
        props.handleEditUrl(item.url)
        setIsOpenEdit(!isOpenEdit);
    }

    const deleteDocument = (path) => {
        const config = crafterConf.getConfig();
        if (path && path !== "") {
            props.handleDisable(true);
            const url = composeUrl(config, process.env.REACT_APP_DOCUMENT_REMOVE + '?crafterSite=' + config.site);
            httpPost(url, {path: path}).subscribe(() => {
                const url = composeUrl(config, process.env.REACT_APP_ARTICLE_REMOVE_DOCUMENT_ENDPOINT + '?crafterSite=' + config.site);
                const params = {
                    path: '/site/website' + props.url + '/index.xml',
                    document: path
                }
                httpPost(url, params).subscribe(() => {
                    props.close();
                    props.refresh();
                });
            });
        }
    }

    const renderItem = (items, isRoot) => (
        <ul className={isRoot ? "itemsWrapperRoot" : "itemsWrapper"}>
            {items.map((item, index) =>
                <Item key={index}
                      name={item.name}
                      userRole={props.userRole}
                      onEdit={() => handleEdit(item)}
                      onAdd={() => onAddDocument(item)}
                      onDelete={() => props.open(item.name, item.url, deleteDocument)}
                      isFolder={item.isFolder}
                      url={item.url}>
                    {item.isFolder && renderItem(item.subItems, false)}
                </Item>
            )}
        </ul>
    )


    return (
        <DocumentTreeHolder>
            {/* TODO -- send documentsType onClick */}
            <div className="ui grid row">
                <button className={`main-btn computer only column ${props.titleExist} searchDoc-btn`}
                        onClick={(e) => searchDocOnClickHanlder(e, "docType")}>{props.searchButtonLabel}
                </button>
                <button className={`main-btn tablet mobile only column ${props.titleExist} searchDoc-btn`}
                        onClick={(e) => searchDocOnClickHanlder(e, "docType")}><i className='icon search'></i>
                </button>
            </div>
            {renderItem(tree, true)}
            <input type="file" ref={ hiddenFileInput } onChange={ handleChange } multiple style={{display: 'none'}} />
            <EditMetadata isOpenEdit={isOpenEdit} setIsOpenEdit={setIsOpenEdit}/>
        </DocumentTreeHolder>
    )
}

const mapStateToProps = ({ metadata, descriptor, confirmationDialog }) => ({
    ...metadata,
    filename: confirmationDialog.filename,
    path: confirmationDialog.path,
    searchButtonLabel: descriptor.searchButtonLabel
});

const mapDispatchToProps = dispatch => bindActionCreators({
    handleDisplay,
    handleFiles,
    handleEditUrl,
    handleFolder,
    handleIndex,
    close,
    open,
    handleDisable
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTree);