import React, { useState, useEffect, useRef } from 'react';
import DocumentTreePublicHolder from './DocumentTreePublicStyles';

import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DescriptionIcon from '@material-ui/icons/Description';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { handleDisplay, handleFiles, handleEditUrl } from "../../actions/metadata";

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

const DocumentTreePublic = (props) => {

    const [tree, setTree] = useState([]);
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        let newTree = [];
        for (let i in props.data) {
            let item = props.data[i];
            let path = item.key.replace('/static-assets/', '');

            const getFileFolder = (wholePath, path, allItems) => {
                let items = path.split('/');
                let name = items.shift();
                if (items.length > 0) {
                    let subItem = allItems.find(item => item.name === name);
                    if (subItem) {
                        getFileFolder(wholePath, path.replace(name + '/', ''), subItem.subItems);
                    } else {
                        allItems.push({
                            name: name,
                            url: wholePath.substring(0, wholePath.lastIndexOf('/')),
                            isFolder: true,
                            subItems: getFileFolder(wholePath, path.replace(name + '/', ''), [])
                        })
                    }
                    return allItems;
                }
                allItems.push({
                    name: name,
                    url: wholePath,
                    isFolder: false
                });
                return allItems;
            }

            newTree = getFileFolder(item.key, path, newTree);
        }
        setTree(newTree);
    }, [props.data]);

    const searchDocOnClickHanlder = (e,doctType) => {
        e.preventDefault();
        /* TODO add url with parameters to search page*/
        //window.location = "search/etc/etc";
    }

    const onDocumentClick = (data) => {
        if (!data.isFolder) {
            window.open(data.url, "_blank");
        }

    }


    const renderItem = (items) => {
        return items.map((item, index) =>
            <TreeItem key={index}
                      label={
                          <div>
                              {item.name} 
                          </div>
                      }
                      nodeId={ item.name + index }  
                      onLabelClick={ (e) => onDocumentClick(item)  }
                      >
                {item.isFolder && renderItem(item.subItems)}
            </TreeItem>
        );
    }

    return (
        <DocumentTreePublicHolder>
            <TreeView className="doc-tree"
                      defaultExpanded={['1']}
                      defaultCollapseIcon={<FolderOpenIcon />}
                      defaultExpandIcon={<FolderIcon />}
                      defaultEndIcon={<DescriptionIcon />}>
                {renderItem(tree)}
            </TreeView>
        </DocumentTreePublicHolder>
    )
}

const mapStateToProps = ({ metadata }) => ({...metadata});

const mapDispatchToProps = dispatch => bindActionCreators({
    handleDisplay,
    handleFiles,
    handleEditUrl
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTreePublic);