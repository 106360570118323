import React, {useState, useEffect} from 'react';

import { connect } from 'react-redux'

import { crafterConf, httpGet, httpPost } from "@craftercms/classes";
import { composeUrl } from "@craftercms/utils";

import EditMetadataHolder from './EditMetadataStyles';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { 
    Button, 
    Modal,  
    Icon 
} from 'semantic-ui-react';

const EditMetadata = (props) => {
	const {isOpenEdit, setIsOpenEdit} = props;
	const [title, setTitle] = useState('');
	const [product, setProduct] = useState('');
	const [author, setAuthor] = useState('');
	const [keywords, setKeywords] = useState('');
	const [sourcePath, setSourcePath] = useState('');
	const [isNew, setNew] = useState(true);
	const [isSaveDisabled, setSaveDisabled] = useState(false);
	const [hasError, setHasError] = useState(true);

	useEffect(() => {
		const config = crafterConf.getConfig();
		const url = composeUrl(config, process.env.REACT_APP_DOCUMENT_GET_METADATA_ENDPOINT);
		const params = {path: props.url, crafterSite: config.site}
		httpGet(url, params).subscribe(data => {
			if(data.exists) {
				const  {values} = data
				setTitle(values.title);
				setProduct(values.product);
				setAuthor(values.author);
				setKeywords(values.keywords);
				setSourcePath(values.path);
				setNew(false);
				setHasError(!values.title || values.title === "");
			} else {
				setTitle("");
				setProduct("");
				setAuthor("");
				setKeywords("");
				setSourcePath("");
				setNew(true);
				setHasError(true);
			}
		});
	}, [props.url]);

	const save = (e) => {
		e.preventDefault();
		setSaveDisabled(true);
		const config = crafterConf.getConfig();
		const url = composeUrl(config, process.env.REACT_APP_DOCUMENT_SAVE_METADATA_ENDPOINT + "?crafterSite=" + config.site);
		const body = {
			isNew: isNew,
			title: title,
			product: product,
			author: author,
			keywords: keywords,
			sourcePath: sourcePath,
			targetPath: props.url
		}
		httpPost(url, body).subscribe(() => {
			setSaveDisabled(false);
			props.setIsOpenEdit(false);
		});
	}

	const handleTitle = (e) => {
		const value = e.target.value;
		setTitle(value);
		setHasError(!value || value === "");
	}

	const handleInput = (e, callback) => {
		callback(e.target.value);
	}

	return (
		<EditMetadataHolder>
			<Modal
				size="small"
				onClose={() => setIsOpenEdit(false)}
				onOpen={() => setIsOpenEdit(true)}
				open={ isOpenEdit }
			>
				<Modal.Header> Edit Metadata <Icon link size='large' style={{float: 'right'}}  onClick={() => setIsOpenEdit(false)} name='window close'/></Modal.Header>
				<Modal.Content> 
					<Modal.Description>
						<div style={ {margin: "20px 0"} } className="edit-metadata-box">
							<div className="input-container">
								<TextField
									onChange={ handleTitle }
									id="outlined-required"
									label="Title (required)"
									value={title ? title : ''}
									variant="outlined"
									error={hasError}
									fullWidth
								/>
							</div>
							<FormControl variant="outlined" style={{margin:"20px 0 0", "width": "100%"}}>
								<InputLabel id="metadata-product">Product</InputLabel>
								<Select style={{width: "100%"}}
										id="metadata-product"
										onChange={(e) => handleInput(e, setProduct)}
										value={product ? product : ""}
										label="Product">
									{props.products.map((item, index) =>
										<MenuItem key={ index } value={ item.key }>{ item.value }</MenuItem>
									)}
								</Select>
							</FormControl>
							<div style={ {margin: "20px 0"} } className="input-container">
								<TextField
									onChange={(e) => handleInput(e, setAuthor)}
									id="outlined-required"
									label="Author"
									value={author ? author : ''}
									variant="outlined"
									fullWidth
								/>
							</div>
							<div style={ {margin: "20px 0"} } className="input-container">
								<TextField
									onChange={(e) => handleInput(e, setKeywords)}
									id="outlined-required"
									label="Keywords"
									value={keywords ? keywords : ''}
									variant="outlined"
									fullWidth
								/>
							</div>
						</div>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
						<Button color='black' onClick={() => setIsOpenEdit(false)}>Cancel</Button>
						<Button color='green' disabled={isSaveDisabled || hasError} onClick={save}>Save</Button>
				</Modal.Actions>
			</Modal>
		</EditMetadataHolder>
	);
}

const mapStateToProps = ({ metadata }) => ({
   url: metadata.editUrl,
	products: metadata.products
});

export default connect(mapStateToProps)(EditMetadata);