import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { getItem } from '@craftercms/content';
import { crafterConf } from '@craftercms/classes';
import { withRouter } from 'react-router-dom';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import BreadCrumb from './components/Breadcrumb/Breadcrumb';
import Footer from './components/Footer/Footer';

import Router from './Router';
import AppHolder from './AppStyles';

const siteName = document.getElementById('studioSiteName').innerHTML || process.env.REACT_APP_SITE;

crafterConf.configure({
    site: siteName,
    baseUrl: process.env.REACT_APP_HOST
});

const App = (props) => {
    
    useEffect(() => {
        getItem(process.env.REACT_APP_SECTION_DEFAULT).subscribe((item) => {
            const { component } = item.descriptorDom;
            props.setDescriptor({
                headerUrl: component.header_o.item ? component.header_o.item[0].key : '',
                footerUrl: component.footer_o.item ? component.footer_o.item[0].key : '',
                latestFileLabel: component.latestFileTitle_s,
                searchButtonLabel: component.searchButtonLabel_s,
                dialogFooterMessage: component.dialogFooterMessage_t,
                searchUrl: component.searchURL_s,
                productsUrl: component.productListPath_s,
                deleteMessage: component.deleteMessageDialog_html
            });
        });
    }, [props]);

    return (
        <AppHolder>
            <div className="main-app">
                <nav>
                    <Header isPublicPage={true} />
                </nav>
                <content>
                     <Router/>
                </content>
                <footer>
                    <Footer/>
                </footer>
            </div>
        </AppHolder>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setDescriptor : (component) => dispatch({ type: 'SET', component: component })
})

export default  withRouter(connect(null, mapDispatchToProps)(App));
