import React, {useState} from 'react';

import MetadataTileHolder from "./MetadataTileStyles";
import { FileIcon, defaultStyles } from 'react-file-icon';

import { Input, Dropdown, DropdownItem } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { bindActionCreators} from "redux";
import { handleTitle, handleProduct, handleAuthor, handleKeywords } from "../../actions/metadata";

const MetadataTile = (props) => {

    const fileName = props.item.file.name;
    const extension = fileName.split('.').pop();

    return (
        <MetadataTileHolder>
            <div className="file-box">
                <div className="icon-box">
                        <div className="icon-container">
                            <FileIcon  extension={ extension } {...defaultStyles[extension]}/>
                        </div>
                        <span>{ fileName }</span>
                </div>
                <div className="form-box"> 
                    <div className="input-container">
                        <Input 
                            fluid placeholder="Title (required)" 
                            value={props.item.title}                                
                            onChange={(e) =>
                            props.handleTitle(props.index, e.target.value)} 
                            error={props.item.hasError}
                            disabled={props.isDisabled}
                        />
                    </div>
                    <div className="dropdown-container">
                        <select value={props.item.product}
                                onChange={(e)=> {props.handleProduct(props.index, e.target.value)}}
                                style={props.item.product === "" ? {color: "rgba(0,0,0,0.3"} : {}}
                                placeholder="Product"
                                className="dropdown">
                            <option disabled value="">Product</option>
                            {props.products.map((item, index) =>
                                <option value={item.key}>{ item.value }</option>
                            )}
                        </select>
                    </div>
                    <div className="input-container">
                        <Input 
                            fluid placeholder="Author" 
                            value={props.item.author}              
                            onChange={(e) =>
                            props.handleAuthor(props.index, e.target.value)}
                            disabled={props.isDisabled}
                        />
                    </div>
                    <div className="input-container">
                        <Input fluid placeholder="Keywords" 
                            value={props.item.keywords}
                            onChange={(e) =>
                            props.handleKeywords(props.index, e.target.value)}
                            disabled={props.isDisabled}
                        />
                    </div>
                </div>
            </div>
        </MetadataTileHolder>
    )
}

const mapStateToProps = ({ metadata }) => ({
    products: metadata.products
})

const mapDispatchToProps = dispatch => bindActionCreators({
    handleTitle,
    handleProduct,
    handleAuthor,
    handleKeywords
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MetadataTile);