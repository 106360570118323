import React from 'react'

import { Checkbox, Form } from 'semantic-ui-react';
import SortsHolder from './SortsStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { handleSort } from '../../actions/search'

const Sorts = (props) => {

    return (
        <SortsHolder>
            <Form id="sortFilters">
                <Form.Field>
                    <Checkbox
                        radio
                        label="Most Relevant"
                        value="relevant"
                        checked={props.sort === "relevant"}
                        onChange={(e, {value}) => props.handleSort(value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        radio
                        label="Most Recent"
                        value="date"
                        checked={props.sort === "date"}
                        onChange={ (e, {value}) => props.handleSort(value) }
                    />
                </Form.Field>
            </Form>
        </SortsHolder>
    )
}

const mapStateToProps = ({ search }) => ({sort: search.sort})

const mapDispatchToProps = dispatch => bindActionCreators({ handleSort }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sorts);