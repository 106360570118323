import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import { Dropdown, Input } from 'semantic-ui-react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { getItem } from '@craftercms/content';

import HeaderHolder from './HeaderStyles';



const Header = (props) => {
    const [pageOpened, setPageOpened] = useState('/home');
    const [logo, setLogo] = useState("");
    const [links, setLinks] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [helpLabel, setHelpLabel] = useState("");
    const [emergencyNotification, setEmergencyNotification] = useState("");
    const [background_s, setBackground_s] = useState({});
    const defaultBg = '/static-assets/images/header/header-background.png';
   
    useEffect(() => {
        if (props.location.pathname !== pageOpened) {
            setPageOpened(props.location.pathname);
        }
    }, [props.location.pathname, pageOpened]);

    useEffect(() => {
        if (props.url) {
            getItem(props.url).subscribe((item) => {
                const {component} = item.descriptorDom;
                setLogo(component.logo_s);
                setHelpLabel(component.helpLabel_s);
                setEmergencyNotification(component.emergencyNotification_html);
                const bgSytles = {
                    backgroundImage: `url(${ component.background_s ? component.background_s : defaultBg })`,
                  };
                setBackground_s(bgSytles);

                if (component.links_o) {
                    setLinks(component.links_o.item.map((link, index) => {
                        return {key: index, text: link.label_s, value: link.url_s}
                    }));
                }
            });
        }
    }, [props.url]);

    const renderLogo = () => {
        return (
            <img src={logo} className="header__logo" alt="logo"/>
        )
    }

    const renderUserLink = () => {
        return (
            <span className="drop-container" title={ helpLabel }>
                <p></p>
                <Dropdown className="nav-profile"
                    inline
                    trigger={<AccountCircleIcon className="header__profile__icon"/>}
                    value={pageOpened}
                    options={links}
                    onChange={(e, data) => handleOnChange(data)}/>
            </span>
        )
    }

    const handleOnChange = ({value}) => {
        window.open(value, "_blank");
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            props.history.push(props.searchUrl + "?keyword=" + searchText);
        }
    }

    const onSearchTextChange = (event) => {
        setSearchText(event.target.value);
    }

    return (
        <HeaderHolder>
            {emergencyNotification !== null ? (
                <div className="emergency-notification">
                    <div dangerouslySetInnerHTML={{__html: emergencyNotification}} />
                </div>
            ) : null}
            <header className="header" style={background_s}>
                {renderLogo()}
                {!props.isPublicPage ? (
                    <Input className='icon' value={searchText} onChange={onSearchTextChange} icon='search'
                           placeholder='Search...' onKeyDown={onEnterSearch}/>
                ) : null}
                {!props.isPublicPage ? renderUserLink() : null}
            </header>
            {/* props.location.pathname !== '/' && <Breadcrumb url={ props.location.pathname } /> */}
        </HeaderHolder>
    );
}

const mapStateToProps = ({descriptor}) => ({
    url: descriptor.headerUrl,
    searchUrl: descriptor.searchUrl
});

export default withRouter(connect(mapStateToProps)(Header));