import styled from 'styled-components';

const SearchCHolder = styled.div`
    background: white;
    padding: 20px 0px 0px 0px;
    width: 100%;
  
    em {
        font-weight: bold;
    }

    .search-container {
        padding: 0 20px 30px 20px;
        .input {
            width: 100%;
        }
    }

    .results-container {
        padding-left: 20px;
        &__header {
            background-color:rgba(50,63,63,0.08);
            margin: 0;
            padding: 10px;
            margin-left: -20px;
            span {
                margin-left:10px;
            }
        }
    }

    .results , .message , .transition {
        display: none;
        box-shadow: none;
        border:none;
        width: 0 !important;
    }
  
    .row {
        display: inline-block;
        font-size: 14px;
        margin: 0 0 0 5px;
    }

    .file-icon {
        display: inline-block;
        width: 10px;
    }

    .result {
        margin: 20px 0 20px 0;
        border-bottom: 1px solid lightgray;
        padding-bottom: 10px;
        .title {
            font-size: 20px;
            margin: 0;
            display: block;
        }
        .desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            font-size: 14px;
            margin: 0;
        }
        .date {
            font-size: 12px;
            color: gray;
            display: inline-block;
            margin: 0;
        }
        .author {
            font-size: 12px;
            color: gray;
            display: inline-block;
            margin: 0;
            margin-left:10px;
        }
        .category {
            font-size: 12px;
            color: green;
            display: inline-block; 
            padding-left: 15px;   
            margin: 0; 
        }
        .MuiSvgIcon-root {
            vertical-align: middle;
        }
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    .ui.input>input { 
        border-radius: .28571429rem !important;
    }
  
    .removeBtn {
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }

`;

export default SearchCHolder;