import React  from 'react'

import { Checkbox, Form } from 'semantic-ui-react';
import SortsDateHolder from './SortsDateStyles';
import { connect } from 'react-redux'
import { handleRange } from "../../actions/search";
import { bindActionCreators } from "redux";

const SortsDate = (props) => {

	return (
		<SortsDateHolder>
			<Form id="sortFilters">
				<Form.Field>
					<Checkbox
						radio
						label={`All Dates (${props.ranges.find(range => range.id === "all").total})`}
						value={"all"}
						checked={props.range === "all"}
						onChange={(e, {value}) => props.handleRange(value)}/>
				</Form.Field>
				<Form.Field>
					<Checkbox
						radio
						label={`Last 30 Days (${props.ranges.find(range => range.id === "last-30d").total})`}
						value={"last-30d"}
						checked={props.range === "last-30d"}
						onChange={(e, {value}) => props.handleRange(value)}/>
				</Form.Field>
				<Form.Field>
					<Checkbox
						radio
						label={`Last 3 Months (${props.ranges.find(range => range.id === "last-3m").total})`}
						value={"last-3m"}
						checked={props.range === "last-3m"}
						onChange={(e, {value}) => props.handleRange(value)}/>
				</Form.Field>
				<Form.Field>
					<Checkbox
						radio
						label={`Last 12 Months (${props.ranges.find(range => range.id === "last-1y").total})`}
						value={"last-1y"}
						checked={props.range === "last-1y"}
						onChange={(e, {value}) => props.handleRange(value)}/>
				</Form.Field>
			</Form>
		</SortsDateHolder>
	)
}

const mapStateToProps = ({ search }) => ({range: search.range})

const mapDispatchToProps = dispatch => bindActionCreators({ handleRange }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SortsDate);
