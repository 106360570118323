const initialState = {
    show: false,
    filename: "",
    path: "",
    disabled: false,
    onContinue: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DIALOG/CLOSE' :
            return {...state, show: false}
        case 'DIALOG/OPEN' :
            return {...state, show: true, filename: action.filename, path: action.path, onContinue: action.onContinue, disabled: false}
        case 'DIALOG/CHANGE_DISABLE' :
            return {...state, disabled: action.value}
        default :
            return state;
    }
}

export default reducer;