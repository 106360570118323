import React, { useEffect, useState } from "react";
import { getItem } from '@craftercms/content';

import LeftNavigation from "../../components/LeftNavigation/LeftNavigation";

import HomeHolder from './HomePageStyles';
import MainHolder from '../../MainStyles';
import Carousel from "../../components/Carousel/Carousel";
import CardMatrix from "../../components/CardMatrix/CardMatrix";

const HomePage = () => {

	const [title, setTitle] = useState("");
	const [sections, setSections] = useState({});
	const [menus, setMenus] = useState([]);
	const [carouselUrl, setCarouselUrl] = useState(null);
	const [cardMatrixUrl, setCardMatrixUrl] = useState(null);
	const iframe = '<iframe src="https://intranet.nrccua.org/HighSchool/McoPosters" width="540" height="450"></iframe>';

	useEffect(() => {
		getItem('/site/website/index.xml').subscribe((item) => {
			const { page } = item.descriptorDom;
			setTitle(page.title_s);
			setSections(page.sections_o);
			setCarouselUrl(page.carousel_o && page.carousel_o.item ? page.carousel_o.item[0].include : carouselUrl);
			setCardMatrixUrl(page.cardMatrix_o && page.cardMatrix_o.item ? page.cardMatrix_o.item[0].include : carouselUrl);
			if (page.homeNav_o) {
				setMenus(page.homeNav_o.item);
			}
    	});
	}, []);

	return(
		<>
			<MainHolder>
				<div className="main-container">
					<div className="left-content">
						{
							menus.map((item, index) => <LeftNavigation key={ index } path={ item.key } type="HOME" />)
						}
					</div>
				<HomeHolder>
					<div className="main-content">
						<div className="article__content">
							{carouselUrl && <Carousel url={carouselUrl}/>}
							{cardMatrixUrl && <CardMatrix url={cardMatrixUrl} />}
							<div className="article__content__text">
								<div className="article__header">
									<h1>{title}</h1>
								</div>
							</div>							
							{
								sections.item && sections.item.map((section, index) => <div key={index} className="article__rte-content" dangerouslySetInnerHTML={{__html:section.section_html}} />)
							}		
						</div>
					</div>
				</HomeHolder>
				</div>
			</MainHolder>
		</>
	)
}

export default HomePage;