import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, Icon, Modal } from "semantic-ui-react";
import * as actions from '../../actions/confirmationDialog';
import style from './style.module.css'
import {bindActionCreators} from "redux";

const ConfirmationDialog = (props) => {

    const parseMessage = (message) => {
        return message ? message.replace("$FILENAME", props.filename) : message;
    }

    return (
        <Modal size="tiny" open={ props.show }>
            <Modal.Header> WARNING <Icon link size='large' className={style.closeIcon} onClick={ props.close } name='window close'/></Modal.Header>
            <Modal.Content>
                <Modal.Description className={style.contentModal} >
                    <Icon size="huge" name="warning circle" color="yellow"/>
                    <div className={style.contentMessage}>
                        <div dangerouslySetInnerHTML={{__html: parseMessage(props.message)}} />
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={ props.close }>Cancel</Button>
                <Button color='green' disabled={props.disabled} onClick={ () => props.onContinue(props.path) }>Continue</Button>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = ({descriptor, confirmationDialog}) => ({
    ...confirmationDialog,
    message: descriptor.deleteMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators({...actions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);

