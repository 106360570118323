export const handleDisplay = (value) => ({
    type: 'CHANGE_DISPLAY',
    value: value
})

export const handleFiles = (value) => ({
    type: 'CHANGE_FILES',
    value: value
})

export const handleTitle = (index, value) => ({
    type: 'CHANGE_TITLE',
    index: index,
    value: value,
    hasError: value === ''
})

export const handleProduct = (index, value) => ({
    type: 'CHANGE_PRODUCT',
    index: index,
    value: value
})

export const handleProducts = (values) => ({
    type: 'CHANGE_PRODUCTS',
    values: values
})

export const handleAuthor = (index, value) => ({
    type: 'CHANGE_AUTHOR',
    index: index,
    value: value
})

export const handleKeywords = (index, value) => ({
    type: 'CHANGE_KEYWORDS',
    index: index,
    value: value
})

export const handleEditUrl = (value) => ({
    type: 'CHANGE_EDIT_URL',
    value: value
})

export const handleFolder = (value) => ({
    type: 'CHANGE_FOLDER',
    value: value
})

export const handleIndex = (value) => ({
    type: 'CHANGE_INDEX',
    value: value
})




