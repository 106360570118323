const handleItem = (value, actionType) => ({
    type: actionType,
    value: value
})

const handleArray = (item, items, actionType) => {
    if (item) {
        let newItems = Array.from(items);

        if (item.checked) {
            newItems.push(item.value);
        } else {
            const index = newItems.indexOf(item.value);
            newItems.splice(index, 1);
        }
        return {type: actionType, value: newItems}
    }
    return {type: actionType, value: items}
}

export const handleSort = (value) => {
    if (!value) {
        value = "relevant";
    }
    return handleItem(value, 'CHANGE_SORT');
}

export const handleRange = (value) => {
    if (!value) {
        value = "all";
    }
    return handleItem(value, 'CHANGE_RANGE');
}

export const handleCategories = (category, categories) => {
    if (categories) {
        return handleArray(category, categories, 'CHANGE_CATEGORIES');
    } else {
        if (category) {
            if (!Array.isArray(category)) {
                return handleArray(null, [category], 'CHANGE_CATEGORIES');
            }
            return handleArray(null, category, 'CHANGE_CATEGORIES');
        } else {
            return handleArray(null, [], 'CHANGE_CATEGORIES');
        }
    }
}

export const handleDocumentTypes = (documentType, documentTypes) => {
    if (documentTypes) {
        return handleArray(documentType, documentTypes, 'CHANGE_DOCUMENT_TYPES');
    } else {
        if (documentType) {
            if (!Array.isArray(documentType)) {
                return handleArray(null, [documentType], 'CHANGE_DOCUMENT_TYPES');
            }
            return handleArray(null, documentType, 'CHANGE_DOCUMENT_TYPES');
        } else {
            return handleArray(null, [], 'CHANGE_DOCUMENT_TYPES');
        }
    }
}

export const handleDocumentTags = (documentTag, documentTags) => {
    if (documentTags) {
        return handleArray(documentTag, documentTags, 'CHANGE_DOCUMENT_TAGS');
    } else {
        if (documentTag) {
            if (!Array.isArray(documentTag)) {
                return handleArray(null, [documentTag], 'CHANGE_DOCUMENT_TAGS');
            }
            return handleArray(null, documentTag, 'CHANGE_DOCUMENT_TAGS');
        } else {
            return handleArray(null, [], 'CHANGE_DOCUMENT_TAGS');
        }
    }
}

export const handleKeyword = (value) => handleItem(value, 'CHANGE_KEYWORD');

export const handleTotal = (value) => handleItem(value, 'CHANGE_TOTAL');

export const handlePages = (value) => handleItem(value, 'CHANGE_PAGES');

export const handlePage = (value) =>  {
    if (!value) {
        value = 1;
    }
    return handleItem(value, 'CHANGE_PAGE');
}

export const handleLoading = (value) =>  handleItem(value, 'CHANGE_LOADING');

export const handleTyping = (value) =>  handleItem(value, 'CHANGE_TYPING');

export const handlePageFilter = (value) =>  handleItem(value, 'CHANGE_PAGE_FILTER_SELECTED');