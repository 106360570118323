export const close = () => ({
    type: 'DIALOG/CLOSE'
});

export const open = (filename, path, onContinue) => ({
    type: 'DIALOG/OPEN',
    filename: filename,
    path: path,
    onContinue: onContinue
});

export const handleDisable = (value) => ({
    type: 'DIALOG/CHANGE_DISABLE',
    value: value
});