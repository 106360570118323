import React from 'react';

import { Pagination } from 'semantic-ui-react';
import { connect } from 'react-redux'
import PaginationHolder from './PaginationStyles';
import { handlePage } from '../../actions/search'
import { bindActionCreators } from "redux";

const PaginationComp = (props) => {
    return (
        <PaginationHolder>
            <div className={`pagination-container ${props.pages > 1 ? "visible" : "hidden" }`}>
				<Pagination activePage={ props.page } totalPages={ props.pages }
                            onPageChange={ (e, {activePage}) => props.handlePage(activePage) } />
            </div>
        </PaginationHolder>
    );
}

const mapStateToProps = ({ search }) => ({
    page: search.page,
    pages: search.pages
})

const mapDispatchToProps = dispatch => bindActionCreators({ handlePage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaginationComp);