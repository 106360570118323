import styled from 'styled-components';

const EditMetadataHolder = styled.div`
   .edit-metadata-box {
        .input-container {
            
        }

        .input-label {
            font-size: 16px;
        }
   }
`;

export default EditMetadataHolder;