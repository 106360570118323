import styled from 'styled-components';

const SortsAndFiltersHolder = styled.div`
    background: white;
    border: 2px solid lightgray;
    padding-top: 0;
	margin-top:0;
	min-width: 170px;

	.ui.vertical {
		width: 100%;
	}
	
    .ui.vertical.text.menu .header.item {
        font-size: 16px;
        color:white;
        background-color: #003359;
        text-align: center;
		margin-top: 0;
		width: 100%;
    }
    .checkbox {
		padding-bottom: 6px;
        input.hidden+label {
            padding-left: 26px;
			font-size: 12px;
		}		
	}
	
	.filtersChck {
		margin-left: 15px;
	}
	.totalType {
		position:absolute;
		margin: 2px 0 0 7px;
	}
`;

export default SortsAndFiltersHolder;