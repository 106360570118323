import React, { useState, useEffect } from 'react';
import LeftNavigationHolder from './LeftNavigationStyles';
import {Menu} from "semantic-ui-react";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {getItem, getNavTree} from "@craftercms/content";
import { withRouter } from 'react-router-dom';

const LeftNavigation = (props) => {

    const [title, setTitle] = useState("");
    const [links, setLinks] = useState([]);
    const [activeItem, setActiveItem] = useState();

    useEffect(() => {
        if (props.type === 'HOME') {
            retrieveHomeItems(props.path);
        } else {
            retrieveArticleItems(props.path);
        }
    }, [props.path, props.type]);

    const retrieveHomeItems = (path) => {
        getItem(path).subscribe((item) => {
            const { component } = item.descriptorDom;
            setTitle(component.label_s);
            if (component.links_o) {
                setLinks(component.links_o.item.map((item) => {
                    return ({
                        label: item.label_s,
                        url: item.url_s,
                        newWindow: item.newWindow_b === 'true'
                    });
                }));
            }
        });
    }

    const retrieveArticleItems = (url) => {
        getNavTree('/site/website' + url, 1, '').subscribe((item) => {
            setTitle(item.label);
            setLinks(item.subItems ? item.subItems : []);
        });
    }

    const handleItemClick = (e, { name, url, newWindow }, ) => {
        setActiveItem(name);
        let urlL = url.toLowerCase()
        if(urlL.includes("http://") || urlL.includes("https://")) {
            if (newWindow) {
                window.open(url, '_blank');
            } else {
                window.open(url, "_self");
            }
        }else {
            props.history.push(url);
        }
    }

    const renderMenuItems = () => {
        return links.map((item, index) => {
            return (
                <Menu.Item
                    key={index}
                    name={item.label}
                    active={activeItem}
                    onClick={handleItemClick}
                    newWindow={ item.newWindow }
                    url={item.url}
                >
                    <span>{ item.label }</span>
                    <ArrowRightIcon/>
                </Menu.Item>
            )
        });
    }

    return (
        <LeftNavigationHolder>
            <Menu vertical>
                <Menu.Item name={title} className="menu-header">
                    {title}
                </Menu.Item>
                {renderMenuItems()}
            </Menu>
        </LeftNavigationHolder>
    )
}

export default withRouter(LeftNavigation);