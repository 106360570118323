import styled from 'styled-components';

const BreadcrumbHolder = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  margin: 0px auto 0 auto;
  align-items: baseline;
  position: relative;
  width: 100%;
  background-color: rgba(250,250,250,0.2)!important;
  border-bottom: 1px solid #003359;
  
  .breadcrumb {
    margin-left: 5%;
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  ul {
    padding: 10px 0px;
    list-style: none;
    margin: 0;
    font-size: 14px;
    
    li {
      display: inline;
      
      a {
        text-decoration: none;
        color: #4183c4;
      }

      span {
        font-weight: 700;
      }
      
    }
    
    li+li:before {
      content: "\\002F";
      color: rgba(0,0,0,.4);;
      padding: 8px;
    }
  }
`;

export default BreadcrumbHolder;