import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { handleDisplay, handleProducts } from "../../actions/metadata";
import { bindActionCreators } from "redux";
import { crafterConf, httpPost } from "@craftercms/classes";
import { getItem } from '@craftercms/content';
import MetadataHolder from "./MetadataStyles";
import MetadataTile from "../MetadataTile/MetadataTile";

import { 
    Button, 
    Modal,  
    Icon 
} from 'semantic-ui-react';
import {composeUrl} from "@craftercms/utils";


const Metadata = (props) => {

    const config = crafterConf.getConfig();
    let processed = [];
    const filesAdded = [];
    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        if (props.productsUrl) {
            getItem(props.productsUrl).subscribe(data => {
                const { component } = data.descriptorDom;
                props.handleProducts(component.items ? component.items.item : []);
            });
        }
    }, [props.productUrl])

    const handleProcessed = (success, currentIndex) => {
        processed = processed.map((item, index) => {
            if (currentIndex === index) {
                return {
                    processed: true,
                    success: success
                }
            }
            return item;
        });
        if (processed.every(value => value.processed)) {
            updateArticle();
        }
    }

    const handleUpload = (e) => {
        e.preventDefault();
        setDisabled(true);
        for (let i in props.selectedFiles) {
            processed.push({processed: false, success: false});
        }
        props.selectedFiles.forEach((file, index) => {
            uploadFile(file, index);
        });
    }

    const uploadFile = (file, index) => {
        const url = composeUrl(config, process.env.REACT_APP_DOCUMENT_ADD + '?crafterSite=' + config.site);
        const formData = new FormData()
        formData.append("path", props.folder);
        formData.append('file', file.file);
        fetch(url, {
            method: 'POST',
            body: formData
        }).then(response => response.json()).then(data => {
            let path = data.browserUri;
            filesAdded.push({path: path, type:path.split('.').pop()});
            handleProcessed(true, index);
            createMetadata(file, path)
        }).catch(error => {
            handleProcessed(false, index);
            console.error(error)
        })
    }

    const createMetadata = (file, target) => {
        const url = composeUrl(config, process.env.REACT_APP_DOCUMENT_SAVE_METADATA_ENDPOINT + "?crafterSite=" + config.site);
        const body = {
            isNew: true,
            title: file.title,
            product: file.product,
            author: file.author,
            keywords: file.keywords,
            sourcePath: null,
            targetPath: target
        }
        httpPost(url, body).subscribe(response => {
            console.log(response);
        });
    }

    const updateArticle = () => {
        const url = composeUrl(config, process.env.REACT_APP_ARTICLE_UPDATE_ENDPOINT + "?crafterSite=" + config.site);
        const body = {
            path: '/site/website' + props.url + '/index.xml',
            index: props.index,
            files: filesAdded
        }
        httpPost(url, body).subscribe(response => {
            props.handleDisplay(false);
            props.refresh();
            setDisabled(false);
        });
    }
   
    return (
        <MetadataHolder>
            <Modal
                size="small"
                onClose={() => props.handleDisplay(false)}
                onOpen={() => props.handleDisplay(true)}
                open={ props.show }
            >
            <Modal.Header> FILES TO UPLOAD <Icon link size='large' style={{float: 'right'}}  onClick={() => props.handleDisplay(false)} name='window close'/></Modal.Header>
            <Modal.Content> 
                    <Modal.Description>
                        <div style={ {maxHeight: '490px', overflowY: 'scroll', overflowX: 'hidden'} }>
                            {props.files.map((item, index) => (
                                <MetadataTile key={index} item={item} index={index} isDisabled={isDisabled} />
                            ))}
                        </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <div style={{textAlign: 'justify', marginBottom: '20px'}}><p style={{fontSize:'16px'}}>{ props.footerMessage }</p></div>
                    <Button color='black' onClick={() => props.handleDisplay(false)}>Cancel</Button>
                    <Button color='green' disabled={ props.hasError || isDisabled } onClick={handleUpload}>Upload</Button>
            </Modal.Actions>
        </Modal>
      </MetadataHolder>
    )
}

const mapStateToProps = ({ metadata, descriptor }) => ({
    show: metadata.show,
    files: metadata.selectedFiles,
    hasError: metadata.hasError,
    footerMessage: descriptor.dialogFooterMessage,
    selectedFiles : metadata.selectedFiles,
    folder: metadata.selectedFolder,
    index: metadata.index,
    productsUrl: descriptor.productsUrl
});

const mapDispatchToProps = dispatch => bindActionCreators({ handleDisplay, handleProducts }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Metadata);