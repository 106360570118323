
import styled from 'styled-components';

const AppHolder = styled.div`
  .main-app {
    display: flex;
    flex-flow: column;
    position:relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  nav {
    z-index: 2 !important;
  }
  content {
      z-index: 1 !important;
  }
  .show {
    display: block !important;
  }
  .hidden {
      display: none !important;
  }
  .no-vis {
      visibility: hidden;
  }
`;

export default AppHolder;