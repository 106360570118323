import styled from 'styled-components';

const DocumentTreePublicHolder = styled.div`
    display:inline-block;
    position: relative;
    vertical-align:top;
    padding: 0 20px 0px 0;
    width: 100%;
    .add-doc-btn {
        position: absolute;
        font-size: 8px;
        padding: 0 5px;
        margin: 0 0 0 5px;
        top: 4px;
        font-weight: bold;
        color: white;
        background-color: #003359;
        font-weight: bold;
    }
    .title {
        font-size: 20px;
        padding: 20px 20px 10px;
        border-bottom: 1px solid #003359;
    }
    .searchDoc-btn {
        position: absolute !important;
        top: -63px;
        width: 117px !important;
        padding: 10px;
        right: 0;
        text-alignt: center !important;

        @media only screen and (max-width: 991px) {
            top: -63px;
            display: block;
            width: 35px !important;
            height: 35px;
            padding: 0 !important;

            .icon {
                margin-top: 0;
                margin-left: 2px;
            }
        }

        &.titleExist {
            top: -63px;
        }
    } 
    .MuiTreeItem-label {
        font-size: 16px;
        &:hover {
            width: auto;
        }
    }
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
        width: auto;
        &:hover {
            width: auto;
        }
    }   
`;

export default DocumentTreePublicHolder;