import styled from 'styled-components';

const MainHolder = styled.div`
    display: flex;
    flex-flow: wrap row;
    background-color:rgba(50,63,63,0.08)!important;
    justify-content: space-between;
    margin: 0px auto 0 auto;
    padding: 20px 5% 40px 5%;
    align-items: baseline;
    position: relative;
    width: 100%;
    min-height: 69vh;

    .main-container {
        display: flex;
        width: 100%;

        @media only screen and (max-width: 991px) {
            display: flex;
            flex-flow: row wrap-reverse !important;
        }
		
		.left-content {
            margin-right: 15px;
            @media only screen and (max-width: 991px) {
                flex: 1;
                min-width: 100%;
                margin-top: 20px;
            }
			.menu-header {
				font-size: 14px;
			}
			span {
				vertical-align: super;
			}
		}
        .main-content {
			width: 100%;
            background-color:white;

            @media only screen and (max-width: 991px) {
                margin-left: 0;
            }
        }
    }

    .tree-title {
        border-bottom: 1px solid #003359;
        max-width: 100px;
    }

    .card {
        width: calc(50% - 20px);
        @media (max-width: 720px) {
            width: 100%;
        }
    }
    .ui.message {
        margin-bottom: 20px;
    }
    .main-message {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
    .main-btn {
        padding: 10px;
        background-color: #003359;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        &:hover{
            background-color: #083d66;
        }
    }
`;

export default MainHolder;