import styled from 'styled-components';

const LeftNavigationHolder = styled.div`

.vertical.menu {
    width: 100%;
    min-width: 222px;

    .item {
        font-family: Arial, serif;
        
        &:hover {
            background-color: #DCEBFC;
        }

        &.menu-header {
            text-align: center;
        }

        &.menu-header:hover {
            background: none;
        }
        
    }

    a.item {
        font-size: 13px;
    }

}

	&:nth-child(2) {
		margin-top:10px;
    }

    @media only screen and (max-width: 991px) {
        .ui.vertical.menu {
            width: 100%;
        }
    }
	
    .ui.vertical.text.menu .header.item {
        font-size: 16px;
        color:white;
        background-color: #003359;
        text-align: center;
        width: 113%;  
        margin-left: -10px;
        margin-top: 0;
    }
	
    .checkbox {
        padding-bottom: 10px;
        input.hidden+label {
            padding-left: 26px;
        }
    }
`;

export default LeftNavigationHolder;