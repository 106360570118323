import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedin , faYoutube } from '@fortawesome/free-brands-svg-icons';
import { connect } from "react-redux";
import FooterHolder from './FooterStyles';
import {getItem} from "@craftercms/content";

const Footer = (props) => {

    const [footer, setFooter] = useState("")

    useEffect(() => {
        if (props.url) {
            getItem(props.url).subscribe((item) => {
                const {component} = item.descriptorDom;
                setFooter(component.footer_html);
            });
        }
    }, [props.url]);

    const Logo = () => {
        return (
            <Link to='/'>
                <img src="/static-assets/images/header/NRCCUA-logo.png" className="footer__logo" alt="logo" />
            </Link>
        )
    }

    const Social = () => {
        return (
            <div className="social-container">
                <Link to={ `https://www.facebook.com/EncouraEdu/` }>
                    <FontAwesomeIcon icon={faFacebook} />
                </Link> 
                <Link to={ `https://www.instagram.com/encouraedu/` }>
                    <FontAwesomeIcon icon={faInstagram} />
                </Link> 
                <Link to={ `https://twitter.com/encouraedu` }>
                    <FontAwesomeIcon icon={faTwitter} />
                </Link> 
                <Link to={ `https://www.linkedin.com/company/11182958/` }>
                    <FontAwesomeIcon icon={faYoutube} />
                </Link> 
                <Link to={ `https://www.linkedin.com/company/11182958/` }>
                    <FontAwesomeIcon icon={faLinkedin} />
                </Link> 
            </div>
        )
    }

    return (
        <FooterHolder>
            <footer className="footer">
                <div className="footer-wrapper" dangerouslySetInnerHTML={{__html: footer}}/>
            </footer>
        </FooterHolder>
    );
}

const mapStateToProps = ({ descriptor }) => ({
    url: descriptor.footerUrl
});

export default connect(mapStateToProps)(Footer);