import React, { useState } from 'react';
import {Icon} from "semantic-ui-react";
import {defaultStyles, FileIcon} from "react-file-icon";
import style from './Item.module.css';

const Item = (props) => {

    const [isOpen, setOpen] = useState(true);

    const ext = (filename) => {
        return filename.split('.').pop();
    }

    const handleClick = () => {
        if (props.isFolder) {
            setOpen(!isOpen);
        } else {
            window.open(props.url, "_blank");
        }
    }

    return (
        <li className={style.wrapper}>
            <div className={style.line} >
                {props.isFolder && <span className={style.floatRight} onClick={ props.onAdd }><Icon name='add square' /></span>}
                {!props.isFolder && props.userRole === process.env.REACT_APP_ROLE_ALL && <span className={style.floatRight} onClick={ props.onDelete }><Icon name='delete' /></span>}
                {!props.isFolder && props.userRole === process.env.REACT_APP_ROLE_ALL && <span className={style.floatRight} onClick={ props.onEdit }><Icon name='edit outline' /></span>}
                <div onClick={ handleClick }>
                    {props.isFolder && <Icon name={isOpen ? "folder outline open" : "folder"} />}
                    {!props.isFolder &&
                        <div className={style.iconWrapper}>
                            <FileIcon extension={ ext(props.name) } {...defaultStyles[ext(props.name)]}/>
                        </div>
                    }
                    <span>{ props.name }</span>
                </div>
            </div>
            <div style={{display: isOpen ? "block" : "none"}}>
                {props.children}
            </div>
        </li>
    )
}

export default Item;