import React, { useEffect, useState } from "react";
import { getTree } from '@craftercms/content';
import { Route, Switch, withRouter } from "react-router-dom";

import HomePage from './containers/Home/HomePage';
import ArticlePage from './containers/Article/ArticlePage';
import ArticlePublicPage from './containers/ArticlePublic/ArticlePublicPage';
import SearchPage from './containers/Search/SearchPage';

const Router = () => {

	const [pages, setPages] = useState([]);
	const HOME_PAGE_URL = "/site/website/";

	const getPath = (str) => {
	  var url = str.replace('/site/website', '').replace('/index.xml', '');
		return url === "" ? "/" : url;
	}

	useEffect(() => {
		getTree(HOME_PAGE_URL, -1).subscribe((data) => {
			var routeList = [];
			
			const processTree = (items, routeList) => {
				for (var index in items) {
					var item = items[index];			
					if (item.children) {
						processTree(item.children, routeList);
					}
					if (item.name === 'index.xml') {				
						routeList.push({'content-type': item.descriptorDom.page['content-type'], 'url':getPath(item.url)});
					}			
				}		
			}

			processTree(data.children, routeList);

			setPages(routeList);
		});
	},[]);

	const renderComponent = (page) => {
		if (page['content-type'] === '/page/article') {
			return <ArticlePage url={page.url} />
		} else if (page['content-type'] === '/page/public-article') {
			return <ArticlePublicPage url={page.url}/>
		} else if (page['content-type'] === '/page/entry') {
			return <HomePage />
		} else if (page['content-type'] === '/page/search') {
			return <SearchPage />
		}
	}

	return (
		<Switch>
			{pages.map((page, index) =>
				<Route key={index} exact path={page.url}>
					{renderComponent(page)}
				</Route>
			)}
		</Switch>
	)	

}

export default withRouter(Router);
