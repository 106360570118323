import React, { useState, useEffect } from 'react';
import { getNavTree } from '@craftercms/content';
import { withRouter } from 'react-router-dom';
import NavigationHolder from './NavigationStyles';

const Navigation = (props) => {
    const { history } = props
    const [links, setLinks] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const HOME_URL = '/site/website/';

    useEffect(() => {
        getNavTree(HOME_URL, 3, '').subscribe((item) => {
            const getLinks = (items) => {
                return items.map((item) => {
                    return (
                        { label: item.label, url: item.url, subItems: (item.subItems.length > 0 ? getLinks(item.subItems) : []) }
                    )
                });
            }
            setLinks(getLinks(item.subItems));
        });
    }, []);

    const handleClick = (e, url) => {
        e.stopPropagation();
        setMenuOpen(false);
        history.push(url);
    }

    const isActive = (url) => {
        return url.split("/")[1] === props.location.pathname.split("/")[1]
    }

    const renderItems = () => {
        return links.map((link, index) => {
            if (link.subItems.length > 0) {
                return (
                    <li key={index}>
                         <div onClick={(e) => handleClick(e, link.url)} className={isActive(link.url) ? 'active' : ''}>
                            <span className="underline">{link.label}</span><i className='icon caret down'></i>
                        </div>
                        <ul>
                            {renderSubItems(link.subItems)}
                        </ul>
                    </li>
                )
            } else {
                return (
                    <li key={index}>
                        <div className={isActive(link.url) ? 'active' : ''} onClick={(e) => handleClick(e, link.url)}>
                            <span className="underline">{link.label}</span>
                        </div>
                    </li>
                )
            }
        });
    }

    const renderSubItems = (subItems) => {
        return subItems.map((subItem, index) => {
            if (subItem.subItems.length > 0) {

                return (

                    <li key={index}>
                        <div onClick={(e) => handleClick(e, subItem.url)}>
                            {subItem.label}
                            <i className='icon caret right'></i>
                        </div>
                        <ul>
                            {renderSubSubItems(subItem.subItems)}
                        </ul>
                    </li>
                )
            } else {
                return (
                    <li key={index}>
                        <div onClick={(e) => handleClick(e, subItem.url)}>
                            {subItem.label}
                        </div>
                    </li>
                )
            }
        });
    }

    const renderSubSubItems = (subSubItems) => {
        return subSubItems.map((subSubItem, index) => {
            return (
                <li key={index}>
                <div onClick={(e) => handleClick(e, subSubItem.url)}>
                    {subSubItem.label}
                </div>
            </li>
            )
        });
    }

    const onMobileMenuClicked = () => {
        menuOpen ? setMenuOpen(false) : setMenuOpen(true)
    }

    return (
        <NavigationHolder>
            <div className="main-nav computer-only">
                <ul>
                    <li>
                        <div className={props.location.pathname === '/' ? 'active' : ''} onClick={(e)=>handleClick(e,"/")} >
                            <span className="underline">Home</span>
                        </div>
                    </li>
                    {renderItems()}
                </ul>
            </div>
        </NavigationHolder>
    );
}

export default withRouter(Navigation);