import React, { useState, useEffect, useRef }from 'react';

import SearchC from '../../components/SearchC/SearchC';
import SortsAndFilters from '../../components/SortsAndFilters/SortsAndFilters';
import MainHolder from '../../MainStyles';
import { crafterConf, httpGet } from "@craftercms/classes";
import { getItem } from '@craftercms/content';
import { composeUrl } from "@craftercms/utils";
import queryString from 'querystring';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import {bindActionCreators} from "redux";
import * as searchActions from '../../actions/search'
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";

const SearchPage = (props) => {
    const [items, setItems] = useState([]);
    const [filters, setFilters] = useState({});

    const [header, setHeader] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [noResultTitle, setNoResultTitle] = useState("");
    const [noResultBody, setNoResultBody] = useState("");
    const [documentMap, setDocumentMap] = useState([])
    const [userRole, setUserRole] = useState("");

    let isLoading = false

    const config = crafterConf.getConfig();

    const timer = useRef();

    useEffect(() => {
        const config = crafterConf.getConfig();
        httpGet(
            composeUrl(config, process.env.REACT_APP_ROLES_ENDPOINT),
            {crafterSite: config.site}
        ).subscribe(data => {
            if (data && data.length > 0) {
                setUserRole(data[0].authority);
            }
        });
    });

    useEffect(() => {
        isLoading = true;
        props.handleLoading(true);
        resetFilter();
        getItem('/site/website' + props.location.pathname + '/index.xml').subscribe((item) => {
            const { page } = item.descriptorDom;
            setHeader(page.information_html);
            setPageSize(parseInt(page.pagination_s));
            setNoResultTitle(page.noResultMessage_s);
            setNoResultBody(page.searchSuggestion_html);
            setDocumentMap(page.documentMap_o ? page.documentMap_o.item : documentMap);
        });
    }, []);

    useEffect(() => {
        isLoading = true;
        props.handleLoading(true);
        const params = queryString.parse(props.location.search.replace('?', ''));
        props.handleKeyword(params.keyword);
        props.handleSort(params.sort);
        props.handleRange(params.range);
        props.handleCategories(params.categories ? params.categories.split(",") : null);
        props.handleDocumentTags(params.tags ? params.tags.split(",") : null);
        props.handleDocumentTypes(params.documentTypes ? params.documentTypes.split(",") : null);
        search({keyword: params.keyword, crafterSite: config.site, userRole: userRole}).subscribe(data => {
            setFilters(data.facets);
            if (Object.keys(params).length === 1) {
                setItems(data.items);
                props.handleTotal(data.total);
                props.handlePages(data.pages);
                props.handleLoading(false);
                isLoading = false;
            } else {
                search({
                    crafterSite: config.site,
                    keyword: params.keyword,
                    sort: params.sort ? params.sort : props.sort,
                    range: params.range ? params.range : props.range,
                    categories: params.categories ? params.categories : props.categories,
                    tags: params.tags ? params.tags : props.tags,
                    documentTypes: params.documentTypes ? params.documentTypes : props.documentTypes
                }).subscribe(data => {
                    setItems(data.items);
                    props.handleTotal(data.total);
                    props.handlePages(data.pages);
                    isLoading = false;
                    props.handleLoading(false);
                })
            }

        });
    }, [props.location.search, userRole]);

    useEffect(() => {
        if (!isLoading && !props.isLoading) {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                if (props.keyword !== "") {
                    props.handleLoading(true);
                    search({keyword: props.keyword, crafterSite: config.site}).subscribe(data => {
                        resetFilter();
                        setFilters(data.facets);
                        setItems(data.items);
                        props.handleTotal(data.total);
                        props.handlePages(data.pages);
                        props.handleLoading(false);
                    });
                }
            }, 1000);
        }
    }, [props.keyword]);

    useEffect(() => {
        if (!isLoading && !props.isLoading) {
            props.handleLoading(true);
            search(getFilterParams()).subscribe(data => {
                resetPage();
                setItems(data.items);
                props.handleTotal(data.total);
                props.handlePages(data.pages);
                props.handleLoading(false);
            });
        }
    }, [props.sort, props.range, props.categories, props.documentTags, props.documentTypes, props.pageFilterSelected]);

    useEffect(() => {
        if (!isLoading && !props.isLoading) {
            props.handleLoading(true);
            search(getPageParams()).subscribe(data => {
                setItems(data.items);
                props.handleLoading(false);
            });
        }
    }, [props.page]);

    const search = (params) =>  {
        const url = composeUrl(config, process.env.REACT_APP_SEARCH_ENDPOINT);
        return httpGet(url, params);
    }

    const resetFilter = () => {
        resetPage();
        props.handleSort();
        props.handleRange();
        props.handleCategories();
        props.handleDocumentTags();
        props.handleDocumentTypes();
    }

    const resetPage = () => {
        props.handlePage();
    }

    const getFilterParams = () => ({
        crafterSite: config.site,
        keyword: props.keyword,
        sort: props.sort,
        range: props.range,
        categories: props.categories,
        documentTypes: props.documentTypes,
        tags: props.documentTags,
        pageFilter: props.pageFilterSelected,
        pageSize: pageSize,
        userRole: userRole
    });

    const getPageParams = () => ({
        crafterSite: config.site,
        keyword: props.keyword,
        sort: props.sort,
        range: props.range,
        categories: props.categories,
        documentTypes: props.documentTypes,
        tags: props.documentTags,
        pageFilter: props.pageFilterSelected,
        pageSize: pageSize,
        page: props.page,
        userRole: userRole
    });

    const refresh = () => {
        props.handleLoading(true);
        search(getFilterParams()).subscribe(data => {
            resetFilter();
            setFilters(data.facets);
            setItems(data.items);
            props.handleTotal(data.total);
            props.handlePages(data.pages);
            props.handleLoading(false);
        });
    }

    return (
        <MainHolder>
            <div className="main-container">
                <div className="left-content">
                    <SortsAndFilters filters={ filters } documentMap={ documentMap } />
                </div>
                <div className="main-content">
                    <SearchC userRole={userRole}
                        refresh={refresh}
                        header={ header }
                        noResultTitle={ noResultTitle }
                        noResultBody={ noResultBody }
                        items={ items }
                        total={ props.total } />
                </div>               
            </div>
            <ConfirmationDialog />
        </MainHolder>
    );
}

const mapStateToProps = ({ search }) => ({ ...search });

const mapDispatchToProps = dispatch => bindActionCreators({...searchActions}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchPage));