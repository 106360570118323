import styled from 'styled-components';

const FooterHolder = styled.div`
    height: 9rem;
    background: #003359;
    width: 100%;
    bottom: 0;
    background-image: url(http://encoura.org/wp-content/uploads/2017/08/Encoura_Fabric_100percentWhite-transparent-01.png)!important;
    background-size: contain;

    .footer {
        display: flex;
        align-items: center;
        max-width: 1350px;
        margin: 0 auto;
        padding: 0 4rem;
        @media (max-width: 720px) {
            flex-flow: wrap;
            justify-content: space-between;
        }
        &__logo {
            width: 10rem;
            height: 9rem;
            object-fit: contain;
			filter: brightness(0) invert(1);
			bottom: 2%;
        }
        .social-container {
			position: absolute;
			bottom: 40px;
            right: 5%;
            a {
                padding: 5px;
                color: white;
            }
        }
        .links {
            position: absolute;
            right: 5%;
            bottom: 20px;
            a {
                color: white;
                font-size: 1rem;
                padding: 5px;
            }
        }

        .footer-wrapper {
            width: 100%;
            height: 100%;
        }
    }
`;

export default FooterHolder;