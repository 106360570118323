import React, { useState, useEffect } from 'react';
import { getNavBreadcrumb } from '@craftercms/content';
import { Link, withRouter } from 'react-router-dom';

import BreadcrumbHolder from "./BreadcrumbStyles";

const Breadcrumb = ({ location }) => {

    const [links, setLinks] = useState([]);

    useEffect(() => {
        getNavBreadcrumb("/site/website" + location.pathname).subscribe((data) => {
            data.shift();
            setLinks(data);
        });
    }, [location.pathname])

    const renderLinks = () => {
        return links.map((item, index) => {
            if(index === links.length - 1 ) {
                return <li key={ index }><span>{ item.label }</span></li>
            }else {
                return <li key={ index }><Link to={ item.url }>{ item.label }</Link></li>
            }
        });
    }

    return (
        <BreadcrumbHolder className={`${links.length > 0 ? "show" : "hidden"}`}>
            <div className="breadcrumb">
                <ul>
                    {renderLinks()}
                </ul>
            </div>
        </BreadcrumbHolder>
    )
}

export default withRouter(Breadcrumb);