import styled from 'styled-components';

const SortsDateHolder = styled.div`
    .ui.radio.checkbox input:checked~.box:before, .ui.radio.checkbox input:checked~label:before,
    .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after, 
    .ui.radio.checkbox .box:before, .ui.radio.checkbox label:before { 
        display: none !important;
    }

    .ui.radio.checkbox {
        font-size: 12px;
    }

    .ui.radio.checkbox.checked label {
        color: #003359;
        font-weight: 700;
        text-decoration: underline;
    }

    .ui.checkbox input { 
        display: none !important;
        
    }

    .field {
        text-align: left;
        margin-bottom: 0 !important;

        .checkbox input.hidden+label {
            padding-left: 18px;
        }

    }

    .checkbox { 
        margin-left: 15px;
    }


`;

export default SortsDateHolder;