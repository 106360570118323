const initialState = {
    sort: "relevant",
    range: "all",
    categories: [],
    documentTypes: [],
    documentTags: [],
    pageFilterSelected: false,
    keyword: "",
    pages: 0,
    page: 1,
    total: 0,
    isLoading: false,
    isTyping: false,
    placeHolder: "Search..."
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_SORT' :
            return {...state, sort: action.value}
        case 'CHANGE_RANGE' :
            return {...state, range: action.value}
        case 'CHANGE_CATEGORIES' :
            return {...state, categories: action.value}
        case 'CHANGE_DOCUMENT_TYPES' :
            return {...state, documentTypes: action.value}
        case 'CHANGE_DOCUMENT_TAGS' :
            return {...state, documentTags: action.value}
        case 'CHANGE_PAGE_FILTER_SELECTED' :
            return {...state, pageFilterSelected: action.value}
        case 'CHANGE_PAGE' :
            return {...state, page: action.value}
        case 'CHANGE_KEYWORD' :
            return {...state, keyword: action.value}
        case 'CHANGE_TOTAL' :
            return {...state, total: action.value}
        case 'CHANGE_PAGES' :
            return {...state, pages: action.value}
        case 'CHANGE_LOADING' :
            return {...state, isLoading: action.value}
        case 'CHANGE_TYPING' :
            return {...state, isTyping: action.value}
        default :
            return state;
    }
}

export default reducer;