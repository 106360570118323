import styled from 'styled-components';

const LatestDocumentsHolder = styled.div`
    width: 100%;
    display:inline-block;
    vertical-align:top;
	margin-bottom:20px;	
	h2 {
		background-color: white;
		margin:0 !important;
		font-size: 28px !important;
		border: none;
		border-bottom: 1px solid #003359;
	}
    .title {
        font-size: 20px;
        padding: 10px 20px 10px;
        border-bottom: 1px solid #003359;
		margin-bottom: 0;
		background-color: rgba(50, 63, 63, 0.08) !important;
    }
    .doc-detail-ico {
        float: left;
        margin-right: 20px;
        margin-left: 10px;
        width: 17px;
    }
    .list {
		margin: 0px 0px 15px 0px;
        padding: 0px;
		border: 1px solid rgba(50,63,63,0.08);
        .date {
            margin-right: 10px;
		}	
		.item {
			cursor:pointer;
		}
    }
    
`;

export default LatestDocumentsHolder;