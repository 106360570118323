import React from 'react'

import {Checkbox, Menu} from 'semantic-ui-react';
import { connect } from 'react-redux'
import SortsAndFiltersHolder from './SortsAndFiltersStyles';
import { bindActionCreators} from "redux";
import { handleCategories, handleDocumentTypes, handleDocumentTags, handlePageFilter } from "../../actions/search";
import Sorts from '../Sorts/Sorts';
import SortsDate from '../SortsDate/SortsDate';

const SortsAndFilter = (props) => {

    const getLabel = (id, mapping) => {
        if (mapping) {
            const item = mapping.find(element => element.key_s === id);
            if (item) {
                return item.value_s;
            }
        }
        return id;
    }

    const renderOptionsChk = (items, values, callback, mapping) => {
        return items && items.map((item, index) => {
            return (
                <div key={ index } className="filtersChck">
                    <Checkbox
                        label={`${getLabel(item.id, mapping)} (${item.total})`}
                        value={ item.id }
                        checked={ values.includes(item.id) }
                        onChange={ (e, checkboxProps ) => callback(checkboxProps, values) }/>
                </div>
            )
        })
    }

    return (
        <SortsAndFiltersHolder>
            <Menu text vertical>
                <Menu.Item header>Sort</Menu.Item>
                <Sorts/>
                {props.filters.ranges && <Menu.Item header>Date</Menu.Item>}
                {props.filters.ranges && <SortsDate ranges={ props.filters.ranges }/>}
                {((props.filters.categories && props.filters.categories.length > 0) ||
                    (props.filters.tags && props.filters.tags.length > 0))
                    && <Menu.Item header>Categories</Menu.Item>}
                {renderOptionsChk(props.filters.categories, props.categories, props.handleCategories)}
                {renderOptionsChk(props.filters.tags, props.documentTags, props.handleDocumentTags)}
                {props.filters.documentTypes && props.filters.documentTypes.length > 0 && <Menu.Item header>Document Type</Menu.Item>}
                {
                    props.filters.pages && props.filters.pages.total > 0 &&
                    <div className="filtersChck">
                        <Checkbox
                            label={`Articles (${props.filters.pages.total})`}
                            value="value"
                            checked={ props.pageFilterSelected }
                            onChange={() => props.handlePageFilter(!props.pageFilterSelected)}
                        />
                    </div>
                }
                {renderOptionsChk(props.filters.documentTypes, props.documentTypes, props.handleDocumentTypes, props.documentMap)}

            </Menu>
        </SortsAndFiltersHolder>
    )
}

const mapStateToProps = ({ search }) => {
    return {
        categories: search.categories,
        documentTypes: search.documentTypes,
        documentTags: search.documentTags,
        pageFilterSelected : search.pageFilterSelected
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    handleCategories,
    handleDocumentTypes,
    handleDocumentTags,
    handlePageFilter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SortsAndFilter);
