import styled from 'styled-components';

const HomeHolder = styled.div`
    width: 100%;
    position: relative;

    @media only screen and (max-width: 991px) {
        min-width: 100%;
        flex: 1;
        margin-top: 20px;
        margin-left: 0px;
    }

    .article {
        width: 100%;
        background-color: white;
        &__header {
            font-size: 28px;
            padding: 20px 20px 10px;
            border-bottom: 1px solid #003359;
            margin-bottom: 20px;
            text-align: center;
        }
        &__description {
            font-size: 16px;
            padding: 20px;
        }
        &__content {
            padding: 10px 20px 10px 20px;
            &__text {
                padding: 20px;
                display: inline-block;
                width: 100%;
                text-align: left;
            }
            img {
                display: block;
                margin: 0 auto;
                width: 100%;
                padding: 20px;
			}
			iframe, video {
				width: 100%;
				min-height: 400px;
			}
        }
        &__video-container {
            padding: 10px 20px 10px 20px;
		}
		&__rte-content {
			img {
				margin: 0 0;
				width: 100% !important;
				height: auto;
			}
		}
    }
`;

export default HomeHolder;