const initialState = {
    show: false,
    selectedFolder: "",
    selectedFiles: [],
    hasError: true,
    index: 0,
    editUrl: '',
    products: []
}

const validate = (currentIndex, selectedFiles) => (selectedFiles.every((element, index) => {
        if (index !== currentIndex) {
            return !element.hasError
        }
        return true;
    })
)

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_DISPLAY' :
            return {...state, show: action.value}
        case 'CHANGE_FOLDER' :
            return {...state, selectedFolder: action.value}
        case 'CHANGE_INDEX' :
            return {...state, index: action.value}
        case 'CHANGE_FILES' :
            return {...state, selectedFiles: action.value}
        case 'CHANGE_EDIT_URL' :
            return {...state, editUrl: action.value}
        case 'CHANGE_PRODUCTS' :
            return {...state, products: action.values}
        case 'CHANGE_TITLE' : {
            const isCompleted = !action.hasError && validate(action.index, state.selectedFiles);
            return {...state, hasError:!isCompleted, selectedFiles: state.selectedFiles.map((item, index) =>
                    index === action.index ? {...item, title: action.value, hasError: action.hasError} : item)}
        }
        case 'CHANGE_PRODUCT' :
            return {...state, selectedFiles: state.selectedFiles.map((item, index) =>
                    index === action.index ? {...item, product: action.value} : item)}
        case 'CHANGE_AUTHOR' :
            return {...state, selectedFiles: state.selectedFiles.map((item, index) =>
                    index === action.index ? {...item, author: action.value} : item)}
        case 'CHANGE_KEYWORDS' :
            return {...state, selectedFiles: state.selectedFiles.map((item, index) =>
                    index === action.index ? {...item, keywords: action.value} : item)}
        default :
            return state;
    }
}

export default reducer;