import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/card.min.css';
import 'semantic-ui-css/components/progress.min.css';
import 'semantic-ui-css/components/dropdown.min.css';

import App from './App';
import './index.css';

ReactDOM.render(
    <Provider store={ store }>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  ,document.getElementById('root')
);