import styled from 'styled-components';

const ArticleHolder = styled.div`
	width: 100%;
	position: relative;

    @media only screen and (max-width: 991px) {
        min-width: 100%;
        flex: 1;
		margin-top: 20px;
		margin-left: 0;
    }

	h2 {
		padding: 20px 20px 10px;
		border-bottom: 1px solid #003359;
		margin-bottom: 20px;
		text-align: center !important;
		font-size: 20px;
		font-weight: normal;
	}
    .article {
		width: 100%;
		background-color: white;
        &__header {
            font-size: 28px;
            padding: 20px 20px 10px;
            border-bottom: 1px solid #003359;
            margin-bottom: 20px;
			text-align: center;
			font-size: 20px;
			font-weight: normal;

			@media only screen and (max-width: 991px) {
				text-align: left;
				padding: 20px 0 10px;
			}

			&:last-of-type {
				text-align: left !important;
			}
        }
        &__description {
            font-size: 16px;
            padding: 20px;
        }
        &__content {
            padding: 20px;
            &__text {
                padding: 20px;
                display: inline-block;
                width: 100%;
                text-align: left;
            }
            img {
                display: block;
                margin: 0 auto;
			}
			iframe, video {
				width: 100%;
				min-height: 400px;
			}

			&__small {

				@media only screen and (max-width: 991px) {
					padding: 20px;
				}

				&__text {
					padding: 20px;
					display: inline-block;
					width: 100%;
					text-align: left;
				}
				img {
					display: block;
					margin: 0 auto;
					height: auto;
					width: 100%;
					padding: 0;
				}
				iframe, video {
					width: 100%;
					min-height: 400px;
				}
			}

		}
        &__video-container {
            padding: 10px 20px 10px 20px;
		}
		&__rte-content {
			width: 100%;
			img {
				margin: 0;
				width: 100% !important;
				height: auto;
			}
		}
	}
	
	.section-full {
		display: inline-block;
		width: 45%;
		vertical-align: top;
		margin-bottom: 20px;
		margin-right: 2.5%;
		margin-left: 2.5%;

		@media only screen and (max-width: 991px) {
			width: 100%;
			margin: 0;
		}


  	}
  
    .section-left, .section-right {
      display: inline-block;
	  width: 100%;
	  margin-bottom: 20px;
	}
	
	.section-left {
		float: left;
	}
  
	.section-right {
    	float: right;
	}
  
`;

export default ArticleHolder;